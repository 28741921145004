import * as React from "react"
import Layout from "../../components/layout"
import Star from "../../components/star"
import '../../style.css'

const Brochure = () => {

    const info={
        title:'The 2001 Swift Brochure',
        image:'sat2.png',
        page:'brochure2001',
        description:'Catching Gamma-Ray bursts on the Fly',
        internalText:["Catching Gamma-Ray Bursts on the Fly ... Gamma-ray bursts are distant explosions that represent the greatest release of energy the Universe has seen since the Big Bang. Satellites detect about one of these bursts a day, and scientists do not know what causes them. Now there is a satellite dedicated to addressing the gamma-ray burst mystery. Swift, a NASA mission with international participation, is planned for a 2003 launch. Swift will find hundreds of these powerful flashes of light each year."],
        pdf:{
          pdf:'2001Brochure.pdf',
          accessible:'2001BrochureDisability.pdf'
        }
    }

  return (
    <Layout>
        <div>
          <h1>{info.title}</h1>
          <Star/>
          <p>{info.internalText}</p>
          {info.pdf.accessible?
          <p>Check out the <a href={`/resourceLinks/${info.pdf.pdf}`} target='_blank' rel="noreferrer">PDF</a> and <a href={`/resourceLinks/${info.pdf.accessible}`} target='_blank' rel="noreferrer">Disability accessible PDF</a></p>
          :
          <p>Check out the <a href={`/resourceLinks/${info.pdf.pdf}`} target='_blank' rel="noreferrer">PDF</a></p>
          }        
        </div>
    </Layout>
  )
}

export default Brochure